import axios from 'axios';
import Places from 'google-places-web';

//https://api.yootechnologies.com/api/cars/3?populate=*
//export const server = "https://localhost:44371/api/";
//export const bserver = 'https://localhost:44371/api/';
export const bserver = '/api/';
export const server = 'https://api.yootechnologies.com/api/';
export const imgServer = 'https://api.yootechnologies.com';
export const token =
  '85f61146d47ea32bc4791fc48f2645b00d06784dba2165582da53dd8b527a06ec49026612624258fef128c416f2d555cad620fdd2d935103306172bfa1c24971381a3281f2e291a3c92c0b779cbae9ba582506dcf00c29d3218e75887c6be34ee66363a7f95d04ae1ce28edb4a0da56c1b3000fb1a597375078628c486526855';

const googleAPIKey = 'AIzaSyBVMvX41R_NPOlPtCFOxKv8pvsw3w_rfFc';
const placeId = 'ChIJAToILfEVK4gROs8I8ljRF58';
//const corsurl = "https://cors-anywhere.herokuapp.com/"
const corsurl = '';
const googleURL =
  corsurl +
  'https://maps.googleapis.com/maps/api/place/details/json?fields=&place_id=' +
  placeId +
  '&key=' +
  googleAPIKey;

const cfClientID = '6m9SBX84vtTW830EowYqt7lQJcTD2VGK';
const cfClientSecret =
  'yoV7Nk8oFkUuI8klm-SLjDTKdwGzxf_BMsuHbY0xY92IO-WayBnM8BeIaPQmtwy4';
const cfAccountNumber = '21691';
let cfToken = '';

Places.apiKey = googleAPIKey;

export const req = axios.create({
  baseURL: server,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

export const breq = axios.create({
  baseURL: bserver,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

req.interceptors.response.use(
  (response) => {
    console.log('response from interceptor');
    console.log(response);
    return response;
  },
  (error) => {
    console.log('interceptor response error ');
    console.log(error);
    return error;
  }
);

export const googlePlaceDataAPI = async () => {
  //   const bizLocData = await Places.details({
  //      placeid: placeId
  //  })
  //  console.log("🚀 ~ file: config.js ~ line 43 ~ googlePlaceDataAPI ~ bizLocData", bizLocData)

  //console.log("session_GoogleData", sessionStorage.getItem("session_GoogleData"))

  try {
    if (sessionStorage.getItem('session_GoogleData') == null) {
      console.log('calling google API');

      const resp = await axios.get(bserver + 'GoogleService');
      sessionStorage.setItem(
        'session_GoogleData',
        JSON.stringify(resp.data.result)
      );
    }
    //console.log("🚀 ~ file: config.js ~ line 52 ~ session_GoogleData", resp)
    return JSON.parse(sessionStorage.getItem('session_GoogleData'));
  } catch (error) {
    return null;
  }
};

export const getWebsiteDataSession = async () => {
  if (sessionStorage.getItem('session_WebsiteData') == null) {
    const respData = await req(
      `cars?populate=*&pagination[pageSize]=200`
    ).catch(function (response) {});
    sessionStorage.setItem(
      'session_WebsiteData',
      JSON.stringify(respData.data.data)
    );
  }

  return JSON.parse(sessionStorage.getItem('session_WebsiteData'));
};

export const getWebsiteData = async () => {
  const respData =
    await req(`cars?filters[Status][$eq]=Available&populate=*&pagination[pageSize]=200
  `).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};

export const getWebsiteSoldData = async () => {
  const respData =
    await req(`cars?filters[Status][$eq]=Sold&populate=*&pagination[pageSize]=200
  `).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};

export const getWebsiteInactiveData = async () => {
  const respData =
    await req(`cars?filters[Status][$eq]=Inactive&populate=*&pagination[pageSize]=200
  `).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};

export const getWebsiteAllDataSummary = async () => {
  const respData = await req(`cars?pagination[pageSize]=500
  `).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};
export const getWebsiteAllDataDetail = async (id) => {
  const respData =
    await req(`cars?filters[id][$eq]=${id}&populate=*&pagination[pageSize]=500
  `).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};

export const getCarFaxReport = async (vn) => {
  /*  const response = await axios.post(
      corsurl + 'https://authentication.carfax.ca/oauth/token',
      'audience=https://api.carfax.ca&grant_type=client_credentials&client_id=6m9SBX84vtTW830EowYqt7lQJcTD2VGK&client_secret=yoV7Nk8oFkUuI8klm-SLjDTKdwGzxf_BMsuHbY0xY92IO-WayBnM8BeIaPQmtwy4',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'did=s%3Av0%3Ad4c8e610-067c-11ec-84ad-83a048983dd7.IfTEs0EGLJ7r5kYxspPDGaSaw9fs2WPxYv5K%2FJv6Rpg; did_compat=s%3Av0%3Ad4c8e610-067c-11ec-84ad-83a048983dd7.IfTEs0EGLJ7r5kYxspPDGaSaw9fs2WPxYv5K%2FJv6Rpg'
        }
      }
    );
  
    cfToken = response.data.access_token;
  */
  const carFax = await axios.get(bserver + 'CarFax?Vin=' + vn);

  return carFax;
};
