import { ctor } from 'from2';
import { async } from 'regenerator-runtime';
import {
  req,
  imgServer,
  googlePlaceDataAPI,
  getWebsiteData,
} from '../js/config';
import { currencyFormatter } from '../js/base';
//import { ToHTML, ToHTMLWithFormatting } from "../js/base";
//import { async } from "regenerator-runtime";
let PageData = {};
const Initialize = async () => {
  try {
    await getPageData();
    console.log('getPageData', PageData);

    await generatePageHTML();

    await loadJavascriptNow();

    const loader = document.querySelector('.loader');
    loader.className += ' hidden'; // class "loader
  } catch (err) {
    console.log(err);

    const loader = document.querySelector('.loader');
    loader.className += ' hidden'; // class "loader
  }
};

const getPageData = async () => {
  // const respData = await req(`cars?populate=*`).catch(function (response) {
  //});
  let WebData = await getWebsiteData();
  PageData = WebData.filter((x) => x.attributes.Status === 'Available');
};

const generatePageHTML = async () => {
  document.querySelector('#yearsInBusiness').innerHTML =
    new Date().getFullYear() - 2006;
  document.querySelector('#carsSold').innerHTML = '1200+';
  document.querySelector('#copyrightYearsFooter').innerHTML =
    '&copy;' +
    new Date().getFullYear() +
    ' <span> Hansen Truck & Car Sales</span';
  document.querySelector('#copyrightYearsMobileMenu').innerHTML =
    '&copy;' +
    new Date().getFullYear() +
    ' <span> Hansen Truck & Car Sales</span';

  await newlyAddedBlock();

  await browseByTypeBlock();

  await inventoryBlock();

  let testimonials = await googlePlaceData();
  if (testimonials != null) {
    await buildTestimonials(testimonials);

    await updateCurrentDayHours(testimonials.opening_hours);

    await phoneNumber(testimonials.formatted_phone_number);

    await address(testimonials.formatted_address);
  }

  await fillBodyTypes();
};

const fillBodyTypes = async (h) => {
  document.querySelector('#typeSedan').innerHTML =
    'Sedan (' +
    PageData.filter((s) => s.attributes.Category == 'Sedan').length +
    ')';
  document.querySelector('#typeWagon').innerHTML =
    'Wagon/Hatchback (' +
    (PageData.filter((s) => s.attributes.Category == 'Wagon').length +
      PageData.filter((s) => s.attributes.Category == 'Hatchback').length) +
    ')';
  document.querySelector('#typeSuv').innerHTML =
    'SUV (' +
    PageData.filter((s) => s.attributes.Category == 'SUV').length +
    ')';
  document.querySelector('#typeCrossover').innerHTML =
    'Crossover (' +
    PageData.filter((s) => s.attributes.Category == 'Crossover').length +
    ')';
  document.querySelector('#typeCoupe').innerHTML =
    'Coupe (' +
    PageData.filter((s) => s.attributes.Category == 'Coupe').length +
    ')';
  document.querySelector('#typeConvertible').innerHTML =
    'Convertible (' +
    PageData.filter((s) => s.attributes.Category == 'Convertible').length +
    ')';
  document.querySelector('#typeSportscar').innerHTML =
    'Sports Car (' +
    PageData.filter((s) => s.attributes.Category == 'Sports Car').length +
    ')';
  document.querySelector('#typePickup').innerHTML =
    'Pickup Truck (' +
    PageData.filter((s) => s.attributes.Category == 'Pickup Truck').length +
    ')';
};

const updateCurrentDayHours = async (h) => {
  let currentDay = new Date().getDay();
  currentDay == 0 ? (currentDay = 7) : currentDay;

  document.querySelector('#currentDayHours').innerHTML =
    h.weekday_text[currentDay - 1];

  let tpa = '';
  for (let i = 0; i < h.weekday_text.length; i++) {
    tpa += `<li>${h.weekday_text[i]}</li>`;
  }
  document.querySelector('#workingHours').innerHTML = tpa;
};

const phoneNumber = async (p) => {
  document.querySelector('#phoneNumber').innerHTML =
    '<a href="tel:+' + p.replace(/[\s\-\(\)]+/g, '') + ' "> ' + p + '</a>';
  document.querySelector('#footerPhoneNumber').innerHTML =
    '<a href="tel:+' + p.replace(/[\s\-\(\)]+/g, '') + ' "> ' + p + '</a>';
};
//<a href="geo:37.421999,-122.0862462" target="_blank">
//Visit Us: 1600 Amphitheatre Parkway, Mountain View, CA
//</a>

const address = async (a) => {
  document.querySelector('#address').innerHTML =
    '<a href="https://www.google.com/maps?q=' +
    a +
    '" target="_blank">' +
    a +
    '</a>';
  document.querySelector('#footerAddress').innerHTML =
    '<a href="https://www.google.com/maps?q=' +
    a +
    '" target="_blank">' +
    a +
    '</a>';
};

const newlyAddedBlock = async () => {
  let tpl = '';

  PageData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id)).forEach((d) => {
    tpl += `<div class="trending-col">
        <div class="single-trending-items">
        <a href="cardetail.html?id=${
          d.id
        }&location=brampton&used-car-for-sale=${
      d.attributes.Year + ' ' + d.attributes.Make + ' ' + d.attributes.Model
    }">
                <img src="${
                  imgServer +
                  d.attributes.Images.data[0].attributes.formats.small.url
                }"
                   
                    alt="">

                <span class="content">
                    <span>${
                      d.attributes.Year +
                      ' ' +
                      d.attributes.Make +
                      ' ' +
                      d.attributes.Model
                    } </span>
                    <span class="price">${currencyFormatter.format(
                      d.attributes.Price
                    )}</span>
                </span>
            </a>
        </div>
    </div>`;
  });

  document.querySelector('#pageIndexNewlyAdded').innerHTML = tpl;
};

const browseByTypeBlock = async () => {};

const generateInlineSlider = (ce, d) => {
  if (d.attributes.formats != null) {
    //console.log('🚀 ~ file: main.js:261 ~ generateInlineSlider ~ x:', d);
    let tpl = `<div class="swiper-slide">
                 <a href="cardetail.html?id=${
                   ce.id
                 }&location=brampton&used-car-for-sale=${
      ce.attributes.Year + ' ' + ce.attributes.Make + ' ' + ce.attributes.Model
    }">
                    <img src="${
                      imgServer + d.attributes.formats.small.url
                    }" alt="">
                 </a>
               </div>`;
    return tpl;
  }
};
const generateInlineSliders = (x) => {
  //console.log('🚀 ~ file: main.js:205 ~ generateInlineSliders ~ x:', x);
  let tpl = ``;

  //x.attributes.Images.data.forEach((d) => (tpl += generateInlineSlider(x, d)));

  if (x.attributes.Images.data.length > 0) {
    tpl += generateInlineSlider(x, x.attributes.Images.data[0]); // Directly access the first image
  }

  return tpl;
};

const inventoryBlock = async () => {
  let tpl = '';

  for (let index = 0; index < PageData.length; index += 2) {
    const ce = PageData[index];
    const ne = index + 1 <= PageData.length ? PageData[index + 1] : -1;

    tpl += `<div class="car-col">
                    <div class="single-car-item mt-50">
                        <div class="car-image">
                            <div class="swiper mySwiper">
                                <div class="swiper-wrapper">
                                ${generateInlineSliders(ce)};
                                </div>
                            </div>
                            <ul class="car-meta">                             
                                <li>
                                    <a href="cardetail.html?id=${
                                      ce.id
                                    }&location=brampton&used-car-for-sale=${
      ce.attributes.Year + ' ' + ce.attributes.Make + ' ' + ce.attributes.Model
    }"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="car-content">
                            <span class="body-type"><a href="#">${
                              ce.attributes.Category
                            }</a></span>
                            <h4 class="car-title">   <a href="cardetail.html?id=${
                              ce.id
                            }&location=brampton&used-car-for-sale=${
      ce.attributes.Year + ' ' + ce.attributes.Make + ' ' + ce.attributes.Model
    }">${
      ce.attributes.Year + ' ' + ce.attributes.Make + ' ' + ce.attributes.Model
    }</a></h4>
                            <span class="price">
                                <span class="price-amount">${currencyFormatter.format(
                                  ce.attributes.Price
                                )}</span>
                            </span>
                        </div>
                    </div>               
    `;
    // console.log("🚀 ~ file: main.js ~ line 112 ~ inventoryBlock ~ ne", ne)
    if (ne !== undefined && ne != -1) {
      tpl += ` <div class="single-car-item mt-50">
            <div class="car-image">
                <div class="swiper mySwiper">
                    <div class="swiper-wrapper">
                        ${generateInlineSliders(ne)};
                    </div>
                </div>


           
                <ul class="car-meta">                             
                    <li>
                        
                           
                            <a href="cardetail.html?id=${
                              ne.id
                            }&location=brampton&used-car-for-sale=${
        ne.attributes.Year +
        ' ' +
        ne.attributes.Make +
        ' ' +
        ne.attributes.Model
      }">
                               
                            </a>
                       
                    </li>
                </ul>
            </div>
            <div class="car-content">
                <span class="body-type"><a href="#">${
                  ne.attributes.Category
                }</a></span>
                <h4 class="car-title"> <a href="cardetail.html?id=${
                  ne.id
                }&location=brampton&used-car-for-sale=${
        ne.attributes.Year +
        ' ' +
        ne.attributes.Make +
        ' ' +
        ne.attributes.Model
      }">${
        ne.attributes.Year +
        ' ' +
        ne.attributes.Make +
        ' ' +
        ne.attributes.Model
      }</a></h4>
                <span class="price">
                    <span class="price-amount">${currencyFormatter.format(
                      ne.attributes.Price
                    )}</span>
                </span>
            </div>
        </div>`;
    }

    tpl += `</div>`;
  }

  document.querySelector('#pageIndexInventory').innerHTML = tpl;
};

const loadJavascriptNow = async () => {
  const script = document.createElement('script');
  script.src = 'assets/js/main.js';

  // Append to the `head` element
  document.head.appendChild(script);

  var swiper = new Swiper('.mySwiper', {});

  $('.mySwiper').on('touchstart', function (event) {
    event.stopPropagation();
  });
};

const googlePlaceData = async () => {
  const data = await googlePlaceDataAPI();
  console.log('🚀 ~ file: main.js ~ line 162 ~ googlePlaceData ~ data', data);
  return data;
};

const buildTestimonialStarRating = (r) => {
  let tmp = '';

  for (let index = 0; index < r.rating; index++) {
    tmp += '<li> <i class="ion-ios-star"></i></li>';
  }

  return tmp;
};

const buildTestimonials = async (t) => {
  console.log(t);
  let tmp = '';

  t.reviews.forEach((r) => {
    tmp += `<div class="testimonial-col">
                <div class="single-testimonial">
                    <div class="testimonial-content">
                        <div class="title-rating d-flex align-items-center">
                            <h3 class="testimonial-title">Rating  </h3>
                            <ul class="testimonial-rating d-flex">
                                ${buildTestimonialStarRating(r)}
                                
                            </ul >
                        </div >
    <p>${r.text} </p>
                    </div >
    <div class="testimonial-author d-flex align-items-center">
        <div class="author-image">
            <img src="${r.profile_photo_url}" alt="">
        </div>
        <div class="author-content media-body">
            <h4 class="author-name">${r.author_name}</h4>
            <div class="designation">${
              r.relative_time_description
            } on <a href="${r.author_url}">Google Reviews</a> </div>
        </div>
    </div>
                </div >
            </div > `;
  });

  document.querySelector('#testimonialData').innerHTML = tmp;
};

Initialize();
