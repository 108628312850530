import notification, { options } from "toastr";

let cfg = {};


export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  



export const renderSpinner = parent => {
    const loader = `<div id="spinner" class="spinner-border text-primary ml-2" role="status">
    <span class="sr-only">Loading...</span>
</div>`;

    parent.insertAdjacentHTML('afterend', loader);
    parent.disabled = true;
};

export const renderSpinnerButton = parent => {
    parent.innerHTML = '';
    const loader = `<span class="spinner-border spinner-border-sm"></span>
        Processing Payment..`;

    parent.insertAdjacentHTML('beforeend', loader);
    parent.disabled = true;
};
export const clearSpinnerButton = parent => {
    parent.innerHTML = 'Pay Now';
    // console.log("clearLoader -> elementStrings.loader", elementStrings.loader)
    parent.disabled = false;
};



export const clearSpinner = () => {
    const loader = document.querySelector(`#spinner`);
    // console.log("clearLoader -> elementStrings.loader", elementStrings.loader)
    console.log("clearLoader -> loader", loader)
    if (loader) {
        loader.parentElement.removeChild(loader);

    }
};



export const notify_Info = (message, title = 'Info') => {

    notification.info(message, title, { positionClass: 'toast-bottom-full-width' });

}

export const notify_Success = (message, title = 'Success') => {

    notification.success(message, title, { positionClass: 'toast-bottom-full-width' });

}

export const notify_Warning = (message, title = 'Warning') => {

    notification.warning(message, title, { positionClass: 'toast-bottom-full-width' });


}

export const notify_Error = (message, title = 'Error') => {

    notification.error(message, title, { positionClass: 'toast-bottom-full-width' });

}